
import { defineComponent } from 'vue'

import { getAuth, sendSignInLinkToEmail } from '@firebase/auth'
import config from '@/configs/config'

export default defineComponent({
  name: 'Login',
  data () {
    return {
      email: '',
      disabled: false,
      sent: false
    }
  },
  methods: {
    async onSubmit () {
      if (this.email !== null && this.email.trim() !== '') {
        this.disabled = true

        try {
          await sendSignInLinkToEmail(getAuth(), this.email, {
            url: this.getAuthUrl('email'),
            handleCodeInApp: true
          })
          window.localStorage.setItem(config.STORAGE.FIREBASE_EMAIL, this.email)
          this.sent = true
        } catch (error) {
          this.$store.commit('onError', error)
          this.sent = false
        } finally {
          this.disabled = false
        }
      }
    },
    getAuthUrl (type: string): string {
      const url = new URL(config.AUTH_ENDPOINT)
      const tag = this.$store.getters.tag as string
      url.searchParams.set('type', type)
      if (tag) {
        url.searchParams.set('tag', tag)
      }
      return url.toString()
    }
  }
})
